@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

.nav a {
  text-decoration: none;
}

.nav a {
  color: #b3b3b3;
}

.nav a.active {
  color: #000;
}

.react-pdf__Page > canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

.color-chip {
  width: 16px;
  height: 16px;
  border: 2px solid #666;
  border-radius: 2px;
}

.job-row > td {
  position: relative;
}

.job-row .b-edit {
  opacity: 0;
}

.job-row:hover {
  background: #fff;
}

.job-row > td:hover .b-edit {
  opacity: 1;
}

.MuiAutocomplete-paper {
  min-width: 500px;
}

.tiny > .MuiButton-startIcon {
  margin-right: 2px;
  margin-left: -8px;
}

@keyframes spin {
  0% { transform: rotate(0) }
  100% { transform: rotate(-360deg) }
}

.spin {
  animation: spin 1s infinite forwards;
}

.form-ctn {
  padding: 15px;
}

.droppable fieldset, .droppable .droppableZone {
  border: 2px dashed #1976d2;
}

.dragover fieldset, .dragover .droppableZone {
  background: rgba(25,118,210,.2);
}

[draggable="true"] {
  cursor: grab;
}

[draggable="true"]:active {
  opacity: .5;
}

.doc-ctn {
  position: relative;
  margin: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  page-break-inside: avoid;
  transition: box-shadow .25s cubic-bezier(0.37, 0, 0.63, 1);
  overflow: hidden;
}

.doc-ctn:hover {
  box-shadow: 0 15px 30px rgba(0,0,0,.25);
}

.actions-ctn {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  background: rgba(255,255,255,.975);
  transition: margin .25s cubic-bezier(0.37, 0, 0.63, 1);
  z-index: 2;
}

.doc-ctn.printing .actions-ctn  {
  margin-top: 150px;
  box-shadow: 0 -5px 10px rgba(0,0,0,.125);
}

.doc-ctn:not(.printing) .actions-ctn  {
  padding: 10px 20px;
}

table.align-top tr {
  vertical-align: top;
}

@media screen {
  .cert-ctn {
    margin: 30px;
  }
  .doc {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

@media print {
  .MuiTypography-body2 {
    font-size: .85rem !important;
    line-height: .95rem !important;
  }
  .doc-ctn:not(.printing), .actions-ctn {
    display: none;
  }
  .cert-card {
    display: none !important;
  }
  .cert-ctn .react-pdf__Page > canvas {
    width: 100% !important;
    height: auto !important;
  }
  .page {
    /* display: table; */
    height: 100vh;
  }
  .page tr {
    page-break-inside: avoid;
  }
  .page-footer {
    /* display: table-footer-group; */
    flex-grow: 1;
  }
}

@page {
  size: 11in 8.5in;
  margin: 0;
}